import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Slider, RadioGroup, FormControlLabel, Radio, ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GrayscaleImageProcessor from './GrayscaleImageProcessor'; // Import the new component

// Custom Button component
const Button = ({ children, className = '', ...props }) => (
  <button
    className={`px-4 py-2 bg-white text-black border border-white rounded transition-colors duration-300 hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 ${className}`}
    {...props}
  >
    {children}
  </button>
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#000000',
    },
  },
});

const sliderSx = {
  width: '90%',
  marginLeft: '8px',
  marginRight: '24px',
  '& .MuiSlider-thumb': {
    backgroundColor: 'white',
  },
  '& .MuiSlider-track': {
    backgroundColor: 'white',
  },
  '& .MuiSlider-rail': {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
};

function PythonExecutor() {
  const [pyodide, setPyodide] = useState(null);
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState('');
  const [loading, setLoading] = useState(true);
  const [processedImageUrl, setProcessedImageUrl] = useState(null);
  const [params, setParams] = useState({
    minRadius: 5,
    maxRadius: 50,
    minDist: 20,
    cannyThreshold1: 50,
    cannyThreshold2: 200,
    contrast: 1,
    brightness: 0,
    method: 'o', // Set Otsu's Thresholding as default
    threshold: 128,
  });
  const [tool, setTool] = useState('none');
  const canvasRef = useRef(null);
  const [manualColonies, setManualColonies] = useState([]);
  const [excludedColonies, setExcludedColonies] = useState([]);
  const [detectedColonies, setDetectedColonies] = useState([]);
  const [isManualMode, setIsManualMode] = useState(false);
  const [manualMode, setManualMode] = useState('none'); // 'none', 'add', or 'delete'
  const [localImageUrl, setLocalImageUrl] = useState(null);
  const fileInputRef = useRef(null);
  const [showGrayscaleProcessor, setShowGrayscaleProcessor] = useState(false); // State to toggle grayscale processor
  const [imageZoom, setImageZoom] = useState(100);
  const [isDemoImage, setIsDemoImage] = useState(false);
  const [countResults, setCountResults] = useState(null);
  const [countHistory, setCountHistory] = useState([]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/pyodide/v0.24.1/full/pyodide.js';
    script.async = true;
    script.onload = async () => {
      const pyodideInstance = await window.loadPyodide({
        indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.24.1/full/'
      });
      
      await pyodideInstance.loadPackage(['numpy', 'opencv-python']);
      
      const counterScript = `
        import numpy as np
        import cv2
        import base64

        def preprocess_image(image, contrast, brightness):
            adjusted = cv2.convertScaleAbs(image, alpha=contrast, beta=brightness)
            return adjusted

        def count_colonies_hough(image, min_radius, max_radius, min_dist, canny_threshold1, canny_threshold2):
            # Convert to grayscale
            gray = cv2.cvtColor(image, cv2.COLOR_BGR2GRAY)
            
            # Apply adaptive thresholding to better isolate white colonies
            thresh = cv2.adaptiveThreshold(gray, 255, cv2.ADAPTIVE_THRESH_GAUSSIAN_C, cv2.THRESH_BINARY, 11, -2)
            
            # Apply morphological operations to clean up the image
            kernel = np.ones((3,3), np.uint8)
            thresh = cv2.morphologyEx(thresh, cv2.MORPH_CLOSE, kernel, iterations=1)
            
            # Apply slight blur to reduce noise while preserving edges
            blurred = cv2.GaussianBlur(thresh, (5, 5), 1.5)
            
            # Apply Canny edge detection
            edges = cv2.Canny(blurred, canny_threshold1, canny_threshold2)
            
            # Find circles
            circles = cv2.HoughCircles(
                edges,
                cv2.HOUGH_GRADIENT,
                dp=1,
                minDist=min_dist,
                param1=canny_threshold1,
                param2=12,  # Lower value to detect more circular shapes
                minRadius=min_radius,
                maxRadius=max_radius
            )
            
            if circles is not None:
                circles = np.round(circles[0, :]).astype("int")
                valid_circles = []
                
                for (x, y, r) in circles:
                    # Create a mask for the current circle
                    mask = np.zeros_like(gray)
                    cv2.circle(mask, (x, y), r, 255, -1)
                    
                    # Calculate the mean intensity and circularity
                    mean_intensity = cv2.mean(gray, mask=mask)[0]
                    contours, _ = cv2.findContours(thresh & mask, cv2.RETR_EXTERNAL, cv2.CHAIN_APPROX_SIMPLE)
                    
                    if contours:
                        contour = max(contours, key=cv2.contourArea)
                        area = cv2.contourArea(contour)
                        perimeter = cv2.arcLength(contour, True)
                        circularity = 4 * np.pi * area / (perimeter * perimeter) if perimeter > 0 else 0
                        
                        # Check if the area is bright enough and roughly circular
                        if mean_intensity > 150 and circularity > 0.4:  # Lowered from 0.6 to 0.4
                            valid_circles.append((x, y, r))
                            cv2.circle(image, (x, y), r, (255, 2, 0), 2)  # #FF0200 in BGR format
                
                return len(valid_circles), image, valid_circles
            return 0, image, []

        def count_colonies_watershed(image):
            gray = cv2.cvtColor(image, cv2.COLOR_BGR2GRAY)
            _, thresh = cv2.threshold(gray, 0, 255, cv2.THRESH_BINARY + cv2.THRESH_OTSU)
            
            kernel = np.ones((3,3), np.uint8)
            opening = cv2.morphologyEx(thresh, cv2.MORPH_OPEN, kernel, iterations=2)
            
            sure_bg = cv2.dilate(opening, kernel, iterations=3)
            dist_transform = cv2.distanceTransform(opening, cv2.DIST_L2, 5)
            _, sure_fg = cv2.threshold(dist_transform, 0.7*dist_transform.max(), 255, 0)
            
            sure_fg = np.uint8(sure_fg)
            unknown = cv2.subtract(sure_bg, sure_fg)
            
            _, markers = cv2.connectedComponents(sure_fg)
            markers = markers + 1
            markers[unknown == 255] = 0
            
            markers = cv2.watershed(image, markers)
            image[markers == -1] = [255, 0, 0]
            
            colony_count = len(np.unique(markers)) - 2
            
            colonies = []
            for label in np.unique(markers):
                if label > 0:
                    mask = np.zeros(gray.shape, dtype="uint8")
                    mask[markers == label] = 255
                    contours, _ = cv2.findContours(mask.copy(), cv2.RETR_EXTERNAL, cv2.CHAIN_APPROX_SIMPLE)
                    cv2.drawContours(image, contours, -1, (255, 2, 0), 2)  # #FF0200 in BGR format
                    M = cv2.moments(contours[0])
                    if M["m00"] != 0:
                        cX = int(M["m10"] / M["m00"])
                        cY = int(M["m01"] / M["m00"])
                        colonies.append([cX, cY, 10])
            
            return colony_count, image, colonies

        def count_colonies_otsu(image):
            gray = cv2.cvtColor(image, cv2.COLOR_BGR2GRAY)
            # Otsu's thresholding
            _, thresh = cv2.threshold(gray, 0, 255, cv2.THRESH_BINARY + cv2.THRESH_OTSU)
            
            # Find contours
            contours, _ = cv2.findContours(thresh, cv2.RETR_EXTERNAL, cv2.CHAIN_APPROX_SIMPLE)
            for contour in contours:
                cv2.drawContours(image, [contour], -1, (255, 2, 0), 2)  # #FF0200 in BGR format
            
            return len(contours), image, [(int(cv2.moments(c)["m10"] / cv2.moments(c)["m00"]), int(cv2.moments(c)["m01"] / cv2.moments(c)["m00"]), 10) for c in contours if cv2.moments(c)["m00"] != 0]

        def count_colonies_contour(image):
            gray = cv2.cvtColor(image, cv2.COLOR_BGR2GRAY)
            _, thresh = cv2.threshold(gray, 0, 255, cv2.THRESH_BINARY + cv2.THRESH_OTSU)
            
            contours, _ = cv2.findContours(thresh, cv2.RETR_EXTERNAL, cv2.CHAIN_APPROX_SIMPLE)
            for contour in contours:
                cv2.drawContours(image, [contour], -1, (255, 2, 0), 2)  # #FF0200 in BGR format
            
            return len(contours), image, [(int(cv2.moments(c)["m10"] / cv2.moments(c)["m00"]), int(cv2.moments(c)["m01"] / cv2.moments(c)["m00"]), 10) for c in contours if cv2.moments(c)["m00"] != 0]

        def process_image(image_path, params):
            image = cv2.imread(image_path)
            # Scale down the image if it exceeds 960x960 pixels
            if image.shape[1] > 960 or image.shape[0] > 960:
                scale_factor = min(960 / image.shape[1], 960 / image.shape[0])
                new_size = (int(image.shape[1] * scale_factor), int(image.shape[0] * scale_factor))
                image = cv2.resize(image, new_size, interpolation=cv2.INTER_AREA)
                
            image = preprocess_image(image, params['contrast'], params['brightness']);
            
            # Apply thresholding correctly
            threshold_value = int(params['threshold'])
            _, thresh = cv2.threshold(image, threshold_value, 255, cv2.THRESH_BINARY)
            
            if params['method'] == 'h':
                count, processed_image, colonies = count_colonies_hough(
                    image, params['minRadius'], params['maxRadius'], params['minDist'],
                    params['cannyThreshold1'], params['cannyThreshold2']
                )
            elif params['method'] == 'o':
                count, processed_image, colonies = count_colonies_otsu(image)
            elif params['method'] == 'c':  # Added condition for Contour Detection
                count, processed_image, colonies = count_colonies_contour(image)
            else:
                count, processed_image, colonies = count_colonies_watershed(image);
            
            _, buffer = cv2.imencode('.png', processed_image)
            img_base64 = base64.b64encode(buffer).decode('utf-8')
            
            return count, img_base64, colonies
      `;
      
      pyodideInstance.runPython(counterScript);
      
      setPyodide(pyodideInstance);
      setLoading(false);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setManualColonies([]);
    setExcludedColonies([]);
    setLocalImageUrl(URL.createObjectURL(file));
    setIsDemoImage(false);
  };

  const processImage = useCallback(async () => {
    if (pyodide && image) {
      try {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const imageData = new Uint8Array(e.target.result);
          pyodide.FS.writeFile(image.name, imageData);
          
          const result = await pyodide.runPythonAsync(`
            params = ${JSON.stringify(params)}
            _, img_base64, colonies = process_image("${image.name}", params)
            [img_base64, colonies]
          `);
          
          setProcessedImageUrl(`data:image/png;base64,${result[0]}`);
          setDetectedColonies(result[1]);
          redrawColonies();
        };
        reader.readAsArrayBuffer(image);
      } catch (error) {
        console.error('Error processing image:', error);
      }
    }
  }, [pyodide, image, params]);

  useEffect(() => {
    processImage();
  }, [processImage]);

  const runColonyCounter = async () => {
    if (pyodide && image) {
      try {
        setOutput('Counting colonies...');
        
        const result = await pyodide.runPythonAsync(`
          params = ${JSON.stringify(params)}
          count, _, _ = process_image("${image.name}", params)
          count
        `);
        
        const manualAddCount = manualColonies.filter(colony => colony.type === 'add').length;
        const manualDeleteCount = manualColonies.filter(colony => colony.type === 'delete').length;
        const totalCount = result + manualAddCount - manualDeleteCount;
        
        const newResult = {
          fileName: image.name,
          totalColonies: totalCount,
          timestamp: new Date().toLocaleString()
        };
        
        // Add to history and set as current result
        setCountHistory(prev => [...prev, newResult]);
        setCountResults(newResult);
        
        setOutput(`Number of colonies: ${totalCount} (Automatic: ${result}, Manual Add: ${manualAddCount}, Manual Delete: ${manualDeleteCount})`);
      } catch (error) {
        setOutput(`Error: ${error.message}`);
      }
    }
  };

  const downloadResultsCSV = () => {
    if (!countResults) return;

    const csvContent = [
      ['Count Number', 'File Name', 'Total Colonies', 'Timestamp'],
      ...countHistory.map((result, index) => [
        index + 1,
        result.fileName,
        result.totalColonies,
        result.timestamp
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'colony_count_results.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleParamChange = (name, value) => {
    setParams(prevParams => ({
      ...prevParams,
      [name]: value
    }));
    
    if (name === 'threshold') {
        processImage(); // Reprocess the image when the threshold changes
    }
  };

  const drawColony = (x, y, color) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.arc(x, y, 5, 0, 2 * Math.PI);
    ctx.fillStyle = color;
    ctx.fill();
  };

  const redrawColonies = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      
      detectedColonies.forEach(([x, y, r]) => {
        if (!excludedColonies.some(([ex, ey]) => Math.abs(ex - x) < 5 && Math.abs(ey - y) < 5)) {
          ctx.beginPath();
          ctx.arc(x, y, r, 0, 2 * Math.PI);
          ctx.strokeStyle = '#00FDFF';
          ctx.lineWidth = 2;
          ctx.stroke();
        }
      });
      
      manualColonies.forEach(({ x, y, type }) => {
        ctx.beginPath();
        ctx.arc(x, y, 5, 0, 2 * Math.PI);
        ctx.fillStyle = type === 'add' ? 'blue' : 'red';
        ctx.fill();
      });
    };
    img.src = processedImageUrl;
  }, [processedImageUrl, detectedColonies, excludedColonies, manualColonies]);

  useEffect(() => {
    if (processedImageUrl) {
      redrawColonies();
    }
  }, [processedImageUrl, detectedColonies, manualColonies, excludedColonies, redrawColonies]);

  const toggleManualMode = () => {
    setIsManualMode(!isManualMode);
    setManualMode('none');
  };

  const handleCanvasClick = (event) => {
    if (!isManualMode || manualMode === 'none') return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    if (manualMode === 'add') {
      setManualColonies([...manualColonies, { x, y, type: 'add' }]);
      drawColony(x, y, 'blue');
    } else if (manualMode === 'delete') {
      // Check if clicking on an existing manual colony
      const existingIndex = manualColonies.findIndex(colony => 
        Math.sqrt((colony.x - x) ** 2 + (colony.y - y) ** 2) <= 10
      );

      if (existingIndex !== -1) {
        // Remove the existing colony
        setManualColonies(manualColonies.filter((_, index) => index !== existingIndex));
        redrawColonies();
      } else {
        // Add a negative colony
        setManualColonies([...manualColonies, { x, y, type: 'delete' }]);
        drawColony(x, y, 'red');
      }
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
    setShowGrayscaleProcessor(false);
    setIsDemoImage(false);
  };

  const toggleGrayscaleProcessor = () => {
    setShowGrayscaleProcessor(!showGrayscaleProcessor);
  };

  const zoomIn = () => {
    setImageZoom(prev => Math.min(prev + 15, 200)); // Max 200% zoom
  };

  const zoomOut = () => {
    setImageZoom(prev => Math.max(prev - 15, 50)); // Min 50% zoom
  };

  const loadDemoImage = async () => {
    try {
      const response = await fetch('/demo/colony_demo.png');
      const blob = await response.blob();
      const file = new File([blob], 'demo_image.png', { type: 'image/png' });
      setImage(file);
      setManualColonies([]);
      setExcludedColonies([]);
      setLocalImageUrl(URL.createObjectURL(file));
      setIsDemoImage(true);
    } catch (error) {
      console.error('Error loading demo image:', error);
    }
  };

  if (loading) {
    return <div>Loading Pyodide and dependencies...</div>;
  }

  return (
    <div className="min-h-screen w-full bg-black text-white">
      <main className="flex flex-col items-center justify-center min-h-screen">
        <div className="flex flex-col items-center w-full max-w-6xl mx-auto">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="hidden"
            ref={fileInputRef}
          />
          <div className="flex flex-col items-center w-full mb-4">
            <div className="flex justify-center w-full mb-2">
              <Tooltip title="Crops and applies a threshold filter to the image, increasing colony detection sensitivity" arrow placement="top">
                <span>
                  <Button
                    onClick={toggleGrayscaleProcessor}
                    className="mr-2"
                  >
                    Image Preprocessing
                  </Button>
                </span>
              </Tooltip>
              <Button
                onClick={handleFileButtonClick}
                className="mr-2"
              >
                Choose File
              </Button>
              <Tooltip title="Don't have a plate image on hand? Try counting colonies on a demo image." arrow placement="top">
                <span>
                  <Button
                    onClick={loadDemoImage}
                  >
                    Demo Image
                  </Button>
                </span>
              </Tooltip>
            </div>
            {isDemoImage && (
              <div className="text-white text-sm italic mt-2">
                You might be seeing a lot of noise in your data, try switching the detection model or change the brightness/contrast sliders
              </div>
            )}
          </div>

          {showGrayscaleProcessor && <GrayscaleImageProcessor />}

          {!showGrayscaleProcessor && (
            <div className="flex w-full justify-center items-start max-w-full overflow-hidden">
              <div className="w-1/2 pr-4 max-w-[50%]">
                <div className="w-full mb-4 text-left">
                  <ThemeProvider theme={theme}>
                    <div className="w-[350px] overflow-visible">
                      <label>Contrast</label>
                      <Slider
                        value={params.contrast}
                        onChange={(_, value) => handleParamChange('contrast', value)}
                        min={0.5}
                        max={3}
                        step={0.1}
                        valueLabelDisplay="auto"
                        aria-labelledby="contrast-slider"
                        sx={sliderSx}
                      />
                      
                      <label>Brightness</label>
                      <Slider
                        value={params.brightness}
                        onChange={(_, value) => handleParamChange('brightness', value)}
                        min={-100}
                        max={100}
                        valueLabelDisplay="auto"
                        aria-labelledby="brightness-slider"
                        sx={sliderSx}
                      />

                      <label>Min Distance</label>
                      <Slider
                        value={params.minDist}
                        onChange={(_, value) => handleParamChange('minDist', value)}
                        min={1}
                        max={100}
                        valueLabelDisplay="auto"
                        aria-labelledby="min-dist-slider"
                        sx={sliderSx}
                      />

                      {params.method === 'h' && (
                        <>
                          <label>Min Radius</label>
                          <Slider
                            value={params.minRadius}
                            onChange={(_, value) => handleParamChange('minRadius', value)}
                            min={1}
                            max={50}
                            valueLabelDisplay="auto"
                            aria-labelledby="min-radius-slider"
                            sx={sliderSx}
                          />
                          <label>Max Radius</label>
                          <Slider
                            value={params.maxRadius}
                            onChange={(_, value) => handleParamChange('maxRadius', value)}
                            min={10}
                            max={200}
                            valueLabelDisplay="auto"
                            aria-labelledby="max-radius-slider"
                            sx={sliderSx}
                          />
                          <label>Canny Threshold 1</label>
                          <Slider
                            value={params.cannyThreshold1}
                            onChange={(_, value) => handleParamChange('cannyThreshold1', value)}
                            min={1}
                            max={255}
                            valueLabelDisplay="auto"
                            aria-labelledby="canny-threshold1-slider"
                            sx={sliderSx}
                          />
                          <label>Canny Threshold 2</label>
                          <Slider
                            value={params.cannyThreshold2}
                            onChange={(_, value) => handleParamChange('cannyThreshold2', value)}
                            min={1}
                            max={255}
                            valueLabelDisplay="auto"
                            aria-labelledby="canny-threshold2-slider"
                            sx={sliderSx}
                          />
                        </>
                      )}

                      <label className="mt-4 block">Detection Method</label>
                      <RadioGroup
                        value={params.method}
                        onChange={(e) => handleParamChange('method', e.target.value)}
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            whiteSpace: 'nowrap',
                            minWidth: '200px'
                          }
                        }}
                      >
                        <FormControlLabel value="o" control={<Radio />} label="Otsu's Thresholding" />
                        <FormControlLabel value="w" control={<Radio />} label="Watershed Transform" />
                        <FormControlLabel value="h" control={<Radio />} label="Hough Circle Transform" />
                      </RadioGroup>
                    </div>
                  </ThemeProvider>
                </div>
                
                <Tooltip title="Manually flag undetected/misidentified colonies" arrow placement="top">
                  <span>
                    <Button
                      onClick={toggleManualMode}
                      className={`mb-4 mr-4 ${!image ? 'opacity-50 cursor-not-allowed' : ''}`}
                      disabled={!image}
                    >
                      {isManualMode ? "Exit Manual Mode" : "Manual Mode"}
                    </Button>
                  </span>
                </Tooltip>

                {isManualMode && (
                  <div className="mb-4">
                    <Button
                      onClick={() => setManualMode('add')}
                      className={`mr-2 ${manualMode === 'add' ? 'bg-black text-white' : ''}`}
                    >
                      Add Colony
                    </Button>
                    <Button
                      onClick={() => setManualMode('delete')}
                      className={manualMode === 'delete' ? 'bg-black text-white' : ''}
                    >
                      Delete/Negate Colony
                    </Button>
                  </div>
                )}

                <Button
                  onClick={runColonyCounter}
                  disabled={!image}
                  className={`mr-2 mb-4 ${!image ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  Count Colonies
                </Button>
                <Button
                  onClick={downloadResultsCSV}
                  disabled={!countResults}
                  className={`mb-4 ${!countResults ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  Download Results
                </Button>
              </div>

              {processedImageUrl && (
                <div className="w-1/2 pl-4 relative">
                  <h3 className="text-xl font-bold mb-2">Processed Image:</h3>
                  <div className="relative">
                    <canvas
                      ref={canvasRef}
                      onClick={handleCanvasClick}
                      style={{ 
                        cursor: isManualMode ? 'crosshair' : 'default', 
                        marginBottom: '8px',
                        maxWidth: '100%',
                        height: 'auto',
                        objectFit: 'contain',
                        transform: `scale(${imageZoom / 100})`,
                        transformOrigin: 'center center'
                      }}
                    />
                    <div className="absolute bottom-4 right-4 flex gap-2">
                      <button
                        onClick={zoomOut}
                        className="w-8 h-8 bg-white text-black rounded-full flex items-center justify-center hover:bg-gray-200 focus:outline-none text-xl leading-none pb-0.5"
                      >
                        -
                      </button>
                      <button
                        onClick={zoomIn}
                        className="w-8 h-8 bg-white text-black rounded-full flex items-center justify-center hover:bg-gray-200 focus:outline-none text-xl leading-none pb-1.5"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <h3 className="text-xl font-bold mb-2">Output:</h3>
                  <pre className="whitespace-pre-wrap">{output}</pre>
                </div>
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );
}

export default PythonExecutor;