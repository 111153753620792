import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Slider, Button } from '@mui/material';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage'; // Utility function to get the cropped image

const GrayscaleImageProcessor = () => {
  const [image, setImage] = useState(null);
  const [binarizedImageUrl, setBinarizedImageUrl] = useState(null);
  const [threshold, setThreshold] = useState(128); // Default threshold value
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropper, setShowCropper] = useState(false); // New state to control cropper visibility
  const fileInputRef = useRef(null);
  const [isPaintMode, setIsPaintMode] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const canvasRef = useRef(null);
  const [paintHistory, setPaintHistory] = useState([]);
  const [currentStroke, setCurrentStroke] = useState(null);
  const BRUSH_RADIUS = 8; // Define brush radius as a constant

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        setImage(img.src);
        setShowCropper(true); // Show cropper when image is uploaded
      };
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      setImage(croppedImage);
      setShowCropper(false); // Hide cropper after cropping
      setBinarizedImageUrl(null); // Reset binarized image URL
    } catch (e) {
      console.error(e);
    }
  }, [image, croppedAreaPixels]);

  const applyThreshold = (canvas, thresholdValue) => {
    const ctx = canvas.getContext('2d');
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
      const binaryValue = avg >= thresholdValue ? 255 : 0; // Binarize based on threshold
      data[i] = binaryValue;     // Red
      data[i + 1] = binaryValue; // Green
      data[i + 2] = binaryValue; // Blue
    }
    ctx.putImageData(imageData, 0, 0);
    setBinarizedImageUrl(canvas.toDataURL());
  };

  const handleThresholdChange = (event, newValue) => {
    setThreshold(newValue);
    if (image) {
      const img = new Image();
      img.src = image; // Use the current image source
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const scaleFactor = Math.min(960 / img.width, 960 / img.height, 1); // Calculate scale factor
        canvas.width = img.width * scaleFactor; // Resize width
        canvas.height = img.height * scaleFactor; // Resize height
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Draw resized image
        applyThreshold(canvas, newValue); // Apply threshold when slider changes
      };
    }
  };

  const downloadBinarizedImage = () => {
    const link = document.createElement('a');
    link.href = binarizedImageUrl;
    link.download = 'binarized_image.png'; // Set the default file name
    link.click();
  };

  const invertImageColors = () => {
    if (binarizedImageUrl) {
      const img = new Image();
      img.src = binarizedImageUrl;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
          data[i] = 255 - data[i];     // Red
          data[i + 1] = 255 - data[i + 1]; // Green
          data[i + 2] = 255 - data[i + 2]; // Blue
        }
        ctx.putImageData(imageData, 0, 0);
        setBinarizedImageUrl(canvas.toDataURL());
      };
    }
  };

  // Add cursor style
  useEffect(() => {
    if (isPaintMode && canvasRef.current) {
      const canvas = canvasRef.current;
      canvas.style.cursor = 'none'; // Hide the default cursor
      
      // Create cursor canvas
      const cursorCanvas = document.createElement('canvas');
      cursorCanvas.width = BRUSH_RADIUS * 2 + 2; // Add 2 for border
      cursorCanvas.height = BRUSH_RADIUS * 2 + 2;
      const ctx = cursorCanvas.getContext('2d');
      
      // Draw circle
      ctx.beginPath();
      ctx.arc(BRUSH_RADIUS + 1, BRUSH_RADIUS + 1, BRUSH_RADIUS, 0, Math.PI * 2);
      ctx.strokeStyle = 'white';
      ctx.lineWidth = 1;
      ctx.stroke();
      
      // Convert to data URL and set as cursor
      const dataURL = cursorCanvas.toDataURL();
      canvas.style.cursor = `url('${dataURL}') ${BRUSH_RADIUS + 1} ${BRUSH_RADIUS + 1}, none`;
    }
  }, [isPaintMode]);

  // Update paint functions to use BRUSH_RADIUS
  const startPainting = (e) => {
    if (!isPaintMode) return;
    setIsDrawing(true);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    // Save the current canvas state before starting a new stroke
    setCurrentStroke(canvas.toDataURL());
    
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    ctx.beginPath();
    ctx.arc(x, y, BRUSH_RADIUS, 0, Math.PI * 2);
    ctx.fillStyle = 'black';
    ctx.fill();
  };

  const paint = (e) => {
    if (!isDrawing || !isPaintMode) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    ctx.beginPath();
    ctx.arc(x, y, BRUSH_RADIUS, 0, Math.PI * 2);
    ctx.fillStyle = 'black';
    ctx.fill();
  };

  const stopPainting = () => {
    if (isDrawing && currentStroke) {
      setPaintHistory([...paintHistory, currentStroke]);
    }
    setIsDrawing(false);
    setCurrentStroke(null);
    if (canvasRef.current) {
      setBinarizedImageUrl(canvasRef.current.toDataURL());
    }
  };

  const handleUndo = () => {
    if (paintHistory.length > 0) {
      const previousState = paintHistory[paintHistory.length - 1];
      const img = new Image();
      img.src = previousState;
      img.onload = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        setBinarizedImageUrl(canvas.toDataURL());
      };
      setPaintHistory(paintHistory.slice(0, -1));
    }
  };

  // Modify the binarized image section to use canvas
  useEffect(() => {
    if (binarizedImageUrl && canvasRef.current) {
      const img = new Image();
      img.src = binarizedImageUrl;
      img.onload = () => {
        const canvas = canvasRef.current;
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
      };
    }
  }, [binarizedImageUrl]);

  return (
    <div className="image-preprocessing" style={{ position: 'relative', minHeight: '100vh' }}>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={fileInputRef}
      />
      {showCropper && image && (
        <div>
          <div className="crop-container mb-4">
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              cropShape="round"
              showGrid={true}
              restrictPosition={false}
              minZoom={0.1}
            />
          </div>
          <div style={{ position: 'fixed', bottom: '20px', left: '50%', transform: 'translateX(-50%)' }}>
            <Button onClick={showCroppedImage} variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>
              Crop
            </Button>
          </div>
          <div style={{ textAlign: 'center', color: 'white', marginTop: '10px' }}>
            Scroll within the cropping window to adjust cropping size,<br /> click and drag to adjust cropping position
          </div>
        </div>
      )}
      {image && !showCropper && (
        <div>
          <h3 className="text-xl font-bold mb-2">Cropped Image:</h3>
          <img src={image} alt="Cropped" style={{ maxWidth: '960px', maxHeight: '960px' }} />
          <div>
            <label>Threshold: {threshold}</label>
            <Slider
              value={threshold}
              onChange={handleThresholdChange}
              min={0}
              max={255}
              valueLabelDisplay="auto"
              sx={{
                color: 'white',
                '& .MuiSlider-thumb': {
                  backgroundColor: 'white',
                },
                '& .MuiSlider-track': {
                  backgroundColor: 'white',
                },
                '& .MuiSlider-rail': {
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
              }}
            />
          </div>
          {binarizedImageUrl && (
            <div>
              <h3 className="text-xl font-bold mb-2">Binarized Image:</h3>
              <canvas
                ref={canvasRef}
                onMouseDown={startPainting}
                onMouseMove={paint}
                onMouseUp={stopPainting}
                onMouseLeave={stopPainting}
                style={{ 
                  maxWidth: '960px', 
                  maxHeight: '960px',
                  cursor: isPaintMode ? 'crosshair' : 'default' 
                }}
              />
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                {isPaintMode && (
                  <Button 
                    variant="contained" 
                    onClick={handleUndo}
                    disabled={paintHistory.length === 0}
                    style={{ 
                      backgroundColor: 'white', 
                      color: 'black', 
                      marginRight: '10px',
                      opacity: paintHistory.length === 0 ? 0.5 : 1
                    }}
                  >
                    Undo
                  </Button>
                )}
                <Button 
                  variant="contained" 
                  onClick={() => setIsPaintMode(!isPaintMode)}
                  style={{ 
                    backgroundColor: isPaintMode ? 'black' : 'white', 
                    color: isPaintMode ? 'white' : 'black', 
                    marginRight: '10px',
                    border: isPaintMode ? '1px solid white' : 'none'
                  }}
                >
                  Paint
                </Button>
                <Button 
                  variant="contained" 
                  onClick={invertImageColors} 
                  style={{ backgroundColor: 'white', color: 'black', marginRight: '10px' }}
                >
                  Invert
                </Button>
                <Button 
                  variant="contained" 
                  onClick={downloadBinarizedImage} 
                  style={{ backgroundColor: 'white', color: 'black' }}
                >
                  Download
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GrayscaleImageProcessor;